import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isOutletDuplicateActive: false,
};

export const duplicateOutletIdSlice = createSlice({
  name: "duplicateOutlet",
  initialState,
  reducers: {
    setOutletDuplicate: (state, action) => {
      state.isOutletDuplicateActive = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOutletDuplicate } = duplicateOutletIdSlice.actions;

export default duplicateOutletIdSlice.reducer;
