import { Box, Grid, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/pageUI/PageHeader";
import CustomButton from "../../../components/formUI/CustomButton";
import StackRow from "../../../components/layoutUI/StackRow";
import PaperBox from "../../../components/layoutUI/PaperBox";
import LargeText from "../../../components/typographyUI/LargeText";
import Text from "../../../components/typographyUI/Text";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomRadiobox from "../../../components/CustomRadiobox";
import Headline4 from "../../../components/typographyUI/Headline4";
import { motion } from "framer-motion";
import UpliadImageFieldWithoutFormik from "../../../components/formUI/UpliadImageFieldWithoutFormik";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
// import {
//   attributeUrl,
//   convertObjectToFormData,
//   dietaryRestrictionsUrl,
//   itemGetSingleUrl,
//   itemImageUrl,
//   itemPutUrl,
//   itemUrl,
//   spiceLevelUrl,
//   subCategoryGetUrl,
// } from "../../../utils/urls";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../../../components/layoutUI/CustomLoader";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CustomTextFieldFormik from "../../../components/formUI/CustomTextFieldFormik";
import CustomSelectFormik from "../../../components/formUI/CustomSelectFormik";
import CustomTextField from "../../../components/formUI/CustomTextField";
import { useGetApis } from "../../../hooks/useGetApis";
import { updatePetPoojaConfigs } from "../../../Services/menuService";
import { authLogin } from "../../../Redux Store/Slices/auth";
import {useSmallScreen} from "../../../utils/mediaQueries"

const ItemAdd = () => {
  const {
    attributeUrl,
    convertObjectToFormData,
    dietaryRestrictionsUrl,
    itemGetSingleUrl,
    itemImageUrl,
    itemPutUrl,
    itemUrl,
    spiceLevelUrl,
    subCategoryGetUrl,
  } = useGetApis();
  const dispatch = useDispatch();
  const location = useLocation();
  const [nav] = useState([
    { id: 1, name: "Item Information", id: "menu-information" },
    { id: 2, name: "Price Details", id: "price-details" },
    { id: 3, name: "Item Media", id: "menu-media" },
    { id: 4, name: "Item Info", id: "more-info" },
  ]);
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const { user } = useSelector((state) => state.auth);
  
  const initialData = {
    restaurantId: user.restaurantId,
    itemname: "",
    attributeid: "",
    price: "",
    itemdescription: "",
    minimumpreparationtime: "",
    kcal: "",
    servinginfo: "",
    item_categoryid: "",
    dietary_restriction_id: "",
    spice_level_id: "",
  };
  const [formData, setFormData] = useState(initialData);
  const navigate = useNavigate();
  const { cId } = useParams();
  const isSmallScreen = useSmallScreen();
  
  const [img, setImg] = useState(null);
  const [isLoadingFormData, setIsLoadingFormData] = useState(null);
  const fetchData = () => {
    setIsLoadingFormData(true);
    axios
      .get(itemGetSingleUrl + cId)
      .then((res) => {
        // console.log("res ",res);
        setFormData(res.data.data); // removed res.data.data[0]
        setIsLoadingFormData(false);
        setImg(res.data.data.item_image_url); //removed res.data.data[0].item_image_url
        
      })
      .catch((err) => setIsLoadingFormData(false));
  };
  useEffect(() => {
    cId && fetchData();
  }, []);

  

  const { data: aList } = useQuery("a-list", () => axios.get(attributeUrl));
  const { data: slList } = useQuery("sl-list", () => axios.get(spiceLevelUrl));
  const { data: drList } = useQuery("dr-list", () =>
    axios.get(dietaryRestrictionsUrl)
  );
  const { data: scList, isLoading: isLoading3 } = useQuery("sc-list", () =>
    axios.get(subCategoryGetUrl + (user.outletId || user.restaurantId))
  );
  
  

  const { mutate, isLoading } = useMutation(
    (data) => {
      if (cId) {
        let postData = { ...data };
        delete postData.restaurantId;
        delete postData.outletId;
        const url = itemPutUrl + data.itemid;
        return axios.post(url, data);
      } else {
        let postData = { ...data };
        if (user.outletId) {
          postData.outletId = user?.outletId;
          
        } else {
          postData.restaurantId = user?.restaurantId;
          
        }
        return axios.post(itemUrl, postData); // new item add
      }
    },
    {
      onSuccess: async (res) => {
        const itemid = res.data.data?.itemid;
        typeof img != "string" &&
          photoMutate({ file: img, itemid: cId || itemid });
        typeof img == "string" &&
          toast.success("Item " + (cId ? "Updated" : "Created"));

        typeof img == "string" && navigate(-1);
        // setImg(res.data.data[0].item_image_url);

        
       
        if (user?.publishProcessingStep <= 1) {
          const postData = {
            publishProcessingStep: 2,
          };
          const updatePetPoojaConfigsResponse = await updatePetPoojaConfigs(
            postData,
            user?.outletId
          );


          if (updatePetPoojaConfigsResponse?.data?.success) {
            localStorage.removeItem("hasShownMenuStep");
            localStorage.removeItem("hasShownStep");
            dispatch(authLogin({ ...user, ...postData }));
          }
        }
      },
      onError: (res) => {
        toast.error(res.response.data.error || "Something Went Wrong");
      },
    }
  );
  const { mutate: photoMutate, isLoading: isLoading2 } = useMutation(
    (data) =>
      axios.post(itemImageUrl, convertObjectToFormData(data), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    {
      onSuccess: () => {
        toast.success("Item " + (cId ? "Updated" : "Created"));
        navigate(-1);
      },
      onError: (res) => {
        toast.error(res.response.data.error || "Something Went Wrong");
      },
    }
  );
  

  return cId && isLoadingFormData ? (
    <CustomLoader />
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          if (!img) return toast.error("Image is required!");
          
          delete values.img;
          mutate(values);
          
          
        }}
        validationSchema={Yup.object().shape({
          itemname: Yup.string()
            .required("Item Name is required")
            .min(3, "Item Name should be at least 3 characters")
            .matches(
              /^[a-zA-Z\s]+$/,
              "Item Name should only contain letters and spaces"
            ),
        })}
      >
        {({ ...props }) => (
          <Form>
        
            <Box>
              <PageHeader
                showBack
                secondary
                title={"Add new product"}
                subTitle={"View and update your store details"}
                rightUi={
                  <CustomButton type="submit" loading={isLoading2 || isLoading}>
                    Save
                  </CustomButton>
                }
              />
              <StackRow gap={"32px"}>
                {
                  isSmallScreen ? "" : <PaperBox
                  shadowed
                  padding={"24px"}
                  sx={{
                    width: "225px",
                    position: "sticky",
                    top: "0",
                    alignSelf: "start",
                  }}
                >
                  <LargeText color="#334155" fontWeight="500" mb={2}>
                    Quick navigation
                  </LargeText>
                  {nav.map((n) => (
                    <Link key={n.id} to={"#" + n.id} style={{ textDecoration: "none" }}>
                      <Text
                        key={n.id}
                        color={
                          location.hash == "#" + n.id || n.id == 1
                            ? "#4F46E5"
                            : "#64748B"
                        }
                        fontWeight={
                          location.hash == "#" + n.id || n.id == 1
                            ? "500"
                            : "400"
                        }
                        mb="20px"
                        onClick={() => handleClickScroll(n.id)}
                        pointer
                      >
                        {n.name}
                      </Text>
                    </Link>
                  ))}
                </PaperBox>
                }
                <PaperBox
                  shadowed
                  padding={"0 24px"}
                  sx={{
                    flex: "1",
                    overflow: "auto",
                    height: "76vh",
                  }}
                >
                  <Box
                    id="menu-information"
                    sx={{
                      pt: "24px",
                      pb: 2,
                      borderBottom: "1px #E2E8F0 solid",
                    }}
                  >
                    <PageHeader
                      bottomBorder
                      secondary
                      title={"Item Information"}
                      subTitle={"View and update your store details"}
                    />
                    <Grid container spacing={"20px"}>
                      <Grid item xs={12}>
                        <CustomTextFieldFormik
                          name="itemname"
                          label="Item Name"
                          placeholder="Enter Item Name"
                          required
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                      <CustomSelectFormik
                        name="item_categoryid"
                        label="Category"
                        placeholder="Select Category"
                        required
                        menuList={cList?.data?.data?.map((c) => ({
                          ...c,
                          id: c.parent_category_id,
                          value: c.parent_category_id,
                          name: c.parentCategoryName,
                        }))}
                      />
                    </Grid>{" "} */}
                      <Grid item xs={6}>
                        <CustomSelectFormik
                          name="item_categoryid"
                          label="Sub Category"
                          placeholder="Select Category"
                          menuList={scList?.data?.data?.map((c) => ({
                            ...c,
                            id: c.categoryid,
                            value: c.categoryid,
                            name: c.categoryname,
                          }))}
                          loading={isLoading3}
                        />
                      </Grid>
                      <Grid item xs={6} />
                      <Grid item xs={6}>
                        <CustomRadiobox
                          name="attributeid"
                          label="Food Type"
                          options={
                            aList?.data?.data?.map((a) => ({
                              ...a,
                              value: a.attributeid,
                              label: a.attribute,
                            })) || []
                          }
                        />

                        {/* <CustomCheckbox name="Category" label="Select Category" /> */}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    id="price-details"
                    sx={{
                      pt: "24px",
                      pb: 2,
                      borderBottom: "1px #E2E8F0 solid",
                    }}
                  >
                    <PageHeader secondary title={"Price Details"} />
                    <Grid container spacing={"20px"}>
                      <Grid item xs={6}>
                        <CustomTextFieldFormik
                          type="number"
                          name="price"
                          label="Base Price"
                          placeholder="Enter Price"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>{" "}
                      {/* <Grid item xs={6}>
                        <StackRow
                          gap={4}
                          // center={true}
                          sx={{ height: "100%", pt: 4.7 }}
                        >
                          <StackRow center gap={2}>
                            <Text>Tax {user.Tax[0].taxname}: </Text>{" "}
                            <Headline4 fontWeight="600">
                              {user.Tax[0].tax}
                              {"%"}
                            </Headline4>
                          </StackRow>
                          <StackRow center gap={2}>
                            <Text>Tax {user.Tax[1].taxname}:</Text>{" "}
                            <Headline4 fontWeight="600">
                              {user.Tax[1].tax}
                              {"%"}
                            </Headline4>
                          </StackRow>
                        </StackRow>
                      </Grid>{" "} */}
                      <Grid item xs={6}>
                        <CustomTextFieldFormik
                          disabled
                          type="number"
                          name="k"
                          label="Total Price"
                          placeholder=""
                          value={props?.values?.price ? props?.values?.price : 0}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextFieldFormik
                          name="itemdescription"
                          label="Item Description"
                          placeholder="Enter Description"
                          multiline
                          rows={4}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    id="menu-media"
                    sx={{
                      pt: "24px",
                      pb: 3,
                      borderBottom: "1px #E2E8F0 solid",
                    }}
                  >
                    <PageHeader secondary title={"Item Media"} />
                    <UpliadImageFieldWithoutFormik
                      defaultValue={formData?.item_image_url}
                      name="img"
                      onChange={setImg}
                      uploadImg={true}
                    />
                  </Box>
                  <Box
                    id="more-info"
                    sx={{
                      pt: "24px",
                      pb: 3,
                      borderBottom: "1px #E2E8F0 solid",
                    }}
                  >
                    <PageHeader secondary title={"Item Info"} />
                    <Grid container spacing={"20px"}>
                      <Grid item xs={6}>
                        <CustomTextFieldFormik
                          name="minimumpreparationtime"
                          label="Preparing Time (in minutes)"
                          placeholder=""
                          type="number"
                        />
                      </Grid>{" "}
                      <Grid item xs={6}>
                        <CustomSelectFormik
                          name="spice_level_id"
                          label="Spice Level"
                          placeholder="Select"
                          menuList={slList?.data?.data?.map((s) => ({
                            id: s.spice_level_id,
                            value: s.spice_level_id,
                            name: s.title,
                          }))}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextFieldFormik
                          name="kcal"
                          label="Kcal"
                          placeholder="Enter Kcal"
                        />
                      </Grid>{" "}
                      <Grid item xs={6}>
                        <CustomTextFieldFormik
                          name="servinginfo"
                          label="Serving Info"
                          placeholder="Enter Serving Info"
                        />
                      </Grid>{" "}
                      <Grid item xs={6}>
                        <CustomSelectFormik
                          name="dietary_restriction_id"
                          label="Select Dietary restrictions (optional)"
                          placeholder="Select"
                          menuList={drList?.data?.data?.map((s) => ({
                            id: s.dietary_restriction_id,
                            value: s.dietary_restriction_id,
                            name: s.title,
                          }))}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </PaperBox>
              </StackRow>
            </Box>
          </Form>
        )}
      </Formik>
    </motion.div>
  );
};

export default ItemAdd;
