import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  Checkbox,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../PageHeader";
import StaffDialog from "../../layoutUI/StaffDialog";
import CustomButton from "../../formUI/CustomButton";
import FormHeader from "../../formUI/FormHeader";
import { Form, Formik, useFormik } from "formik";
import CustomTextFieldFormik from "../../formUI/CustomTextFieldFormik";
import CustomSelectFormik from "../../formUI/CustomSelectFormik";
import PaperBox from "../../layoutUI/PaperBox";
import OnboardDaysFields from "../outlets/OnboardDaysFields";
import { useQuery } from "react-query";
import axios from "axios";
import { useGetApis } from "../../../hooks/useGetApis";
import UpliadImageFieldWithoutFormik from "../../formUI/UpliadImageFieldWithoutFormik";
import { useDropzone } from "react-dropzone";
import SmallText from "../../typographyUI/SmallText";
import Text from "../../typographyUI/Text";
import StackRow from "../../layoutUI/StackRow";
import AddPng from "../../../assets/icons/Add.png";
import ExcelPng from "../../../assets/icons/excel.jpg";
import { toast } from "react-toastify";
import CustomDialog from "../../layoutUI/CustomDialog";
import LargeText from "../../typographyUI/LargeText";
import CustomCheckboxWithFormik from "../../formUI/CustomCheckboxWithFormik";
import { Add, Remove } from "@mui/icons-material";
import CustomChip from "../CustomChip";
import MoreInformation from "./MoreInformation";
import { useDispatch, useSelector } from "react-redux";
import {
  getBankList,
  getCities,
  updateOutletData,
} from "../../../Services/generalService";
import { authLogin } from "../../../Redux Store/Slices/auth";
import { setLoadingSate } from "../../../Redux Store/Slices/loaderSlice";
import PetPoojaDialog from "../../layoutUI/PetPoojaDialog";
import Headline1 from "../../typographyUI/Headline1";
import Headline2 from "../../typographyUI/Headline2";
import CParticles from "./CParticles";
import * as Yup from "yup";
import { mobilePattern, panCardPattern } from "../../../utils/regex";
import imageRounded from "../../../assets/icons/imageRounded.png";
import imageBanner from "../../../assets/icons/imageBanner.png";
import NewFileUploadComponent from "../../formUI/NewFileUploadComponent";
import {
  campusListUrl,
  cityListUrl,
  getCampusByCity,
  bankList,
} from "../../../utils/urls";
import FormikErrorScroller from "../../formUI/FormikErrorScroller";
import CustomCheckbox from "../../formUI/CustomCheckbox";
import CustomLoader from "../../layoutUI/CustomLoader";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {useSmallScreen,useMediumScreen} from "../../../utils/mediaQueries"

const convertToIST = (dateString) => {
  const date = new Date(dateString);
  const istOffset = 5.5 * 60 * 60 * 1000; // IST offset is UTC+5:30

  // Adjust the time zone offset for IST
  const istTime = new Date(date.getTime() + istOffset);

  // Format to ISO string or any other desired format
  const istTimeString = istTime.toISOString();

  return istTimeString;
};

const General = () => {
  const isSmallScreen = useSmallScreen();
  const isMediumScreen = useMediumScreen();
  const restaurantData = useSelector((state) => state.auth.user);
  const outletIdNew = useSelector((state) => state.outledId.outletId);

  const { setMealTime, getMealTime } = useGetApis();

  const [mealTimes, setMealTimes] = useState({
    Breakfast: {
      startTime: null,
      endTime: null,
      rsvpDeadline: null,
      enabled: true,
    },
    Lunch: {
      startTime: null,
      endTime: null,
      rsvpDeadline: null,
      enabled: true,
    },
    Hightea: {
      startTime: null,
      endTime: null,
      rsvpDeadline: null,
      enabled: true,
    },
    Dinner: {
      startTime: null,
      endTime: null,
      rsvpDeadline: null,
      enabled: true,
    },
  });

  const handleCheckboxChange = (meal, isChecked) => {
    setMealTimes((prevMealTimes) => ({
      ...prevMealTimes,
      [meal]: {
        ...prevMealTimes[meal],
        enabled: isChecked,
      },
    }));
  };

  useEffect(() => {
    // Example API call to fetch meal times
    outletIdNew &&
      fetchMealTimesFromApi(outletIdNew, getMealTime)
        .then((data) => {
          setMealTimes(data); // Assuming data is structured similar to state above
        })
        .catch((error) => {
          console.error("Error fetching meal times:", error);
        });
  }, [outletIdNew]);

  const handleTimeChange = (meal, timeType, newValue) => {
    console.log("new value ", newValue);

    setMealTimes((prevMealTimes) => ({
      ...prevMealTimes,
      [meal]: {
        ...prevMealTimes[meal],
        [timeType]: newValue,
      },
    }));
  };

  const handleSubmit = async () => {
    const formattedMealTimes = Object.fromEntries(
      Object.entries(mealTimes).map(([meal, times]) => [
        meal,
        {
          startTime: convertToIST(times.startTime.toISOString()),
          endTime: convertToIST(times.endTime.toISOString()),
          rsvpDeadline: convertToIST(times.rsvpDeadline.toISOString()),
        },
      ])
    );
    console.log("Converted Times:", {
      ...formattedMealTimes,
      outletId: outletIdNew,
    });
    // Submit the convertedTimes as needed
    setLoading(true);

    try {
      const settime = await axios.post(`${setMealTime}`, {
        ...mealTimes,
        outletId: outletIdNew,
      });
      console.log("set meal time res ", settime);
      if (settime?.data?.success) {
        toast.success("Meal Time Updated Successfully");
      }
    } catch (error) {
      console.log("Error while time set api called...");
    } finally {
      setLoading(false);
    }
  };

  const { categoryListUrl, convertObjectToFormData, getOutletData } =
    useGetApis();

  const {
    data,
    isLoading: loadingOutletData,
    isFetching,
  } = useQuery(["get-outlet-data", outletIdNew], () =>
    axios.get(getOutletData + outletIdNew)
  );

  const outletData = data?.data?.data;
  const dispatch = useDispatch();
  const [scrollEltObj, setScrollEltObj] = useState(null);

  // console.log("outletData ", outletData);

  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dep, setDep] = useState(null);

  const [bankList, setBankList] = useState([]);
  const [warning, setWarning] = useState(false);
  const initialState = {
    //resto
    restaurantName: "",
    email: "",
    mobile: "",
    GSTIN: "",
    address: "",
    password: "",
    campusId: "", // FSSAI_License: "",
    accountNumber: "",
    BankName: "",
    IFSCCode: "",
    // owner
    o_name: "",
    o_mobile: "",
    o_email: "",
    o_address: "",
    o_pancard: "",
    // es
    isDineIn: false,
    isPickUp: false,
    isVeg: false,
    isNonVeg: false,
  };
  const daysOfWeek = [
    // { id: 0, day: "All Days", checked: true, startDate: null, endDate: null },
    { id: 1, day: "Monday", checked: true, startDate: null, endDate: null },
    { id: 2, day: "Tuesday", checked: true, startDate: null, endDate: null },
    { id: 3, day: "Wednesday", checked: true, startDate: null, endDate: null },
    { id: 4, day: "Thursday", checked: true, startDate: null, endDate: null },
    { id: 5, day: "Friday", checked: true, startDate: null, endDate: null },
    { id: 6, day: "Saturday", checked: true, startDate: null, endDate: null },
    { id: 7, day: "Sunday", checked: true, startDate: null, endDate: null },
  ];

  const [days, setDays] = useState(daysOfWeek);
  const [formData, setFormData] = useState(initialState);
  const [cityList, setCityList] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);

  const [cityId, setCityId] = useState(restaurantData?.cityId || null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCityName, setSelectedCityName] = useState(
    restaurantData?.campusId?.cityId?.city || ""
  );
  const [selectedCampusName, setSelectedCampusName] = useState(
    restaurantData?.campusId?.campusName || ""
  );

  const { data: campusList } = useQuery(
    ["campus-List", cityId],
    () => cityId && axios.get(campusListUrl + cityId)
  );

  const { data: categoryList } = useQuery(
    "categories-list",
    () => axios.get(categoryListUrl),
    {}
  );
  const [img, setImg] = useState(restaurantData?.FSSAI_License || null);
  const [restoImg, setRestoImg] = useState(null);
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(restaurantData?.headerImage || null);

  const [categories, setCategories] = useState(
    restaurantData?.Restaurant_category?.map((c) => c.categoryId || c) || []
  );

  const [dates, setDates] = useState([]);
  useEffect(() => {}, [dates]);

  useEffect(() => {
    getCities()
      .then((res) => setCityList(res?.data?.data))
      .catch((error) => {});
    getBankList()
      .then((res) => {
        setBankList(res?.data?.data);
      })
      .catch((error) => {});
  }, []);
  //
  const handleSave = (errors) => {
    setDep(Math.random());
    let err =
      typeof Object.entries(errors)?.[0] == "object"
        ? Object.entries(errors)?.[0]
        : Object.entries(errors)?.[0]?.[1];
    let FIELD_NAME = null;
    let ERROR_NAME = null;
    if (typeof Object.entries(errors)?.[0]?.[1] == "string") {
      FIELD_NAME = Object.entries(errors)?.[0]?.[0];
      ERROR_NAME = Object.entries(errors)?.[0]?.[1];
    } else {
      FIELD_NAME = Object.entries(errors)?.[0]?.[1]
        ? Object.entries(Object.entries(errors)?.[0]?.[1])?.[0]?.[0]
        : null;
      ERROR_NAME = Object.entries(errors)?.[0]?.[1]
        ? Object.entries(Object.entries(errors)?.[0]?.[1])?.[0]?.[1]
        : null;
      //
    }
    if (ERROR_NAME) {
      toast.error("Fill all the mandatory fields.");
    }
  };
  const onDrop = useCallback((acceptedFiles) => {
    // Filter acceptedFiles to keep only image files
    const imageFiles = acceptedFiles.filter((file) =>
      file.type.startsWith("image/")
    );
    let file = imageFiles[0];
    if (imageFiles.length === 0) {
      // No image files found, show a warning message
      toast.warning("Please select image files only.");
      return;
    }

    // Do something with the image files
    // For now, we'll just log the first image file.
    setFile(imageFiles[0]);

    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      const imageUrl = `data:${file.type};base64,${base64String}`;
      setImg(imageUrl);
    };

    reader.readAsDataURL(file);
  }, []);
  //
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  //
  //
  //
  const { user } = useSelector((state) => state.auth);

  const handleOutletUpdate = (values) => {
    console.log("vlaues ", values);
    setLoading(true);
    dispatch(setLoadingSate(true));
    let postbody = {
      outletName: values?.outletName,
      mobile: values?.mobile,
      GSTIN: values?.GSTIN,
      campusId: values?.campusId,
      cityId: values.cityId,
      restaurantId: values?.restaurantId,
      address: values.address,
      isActive: values.isActive,
      isDineIn: values.isDineIn,
      isPickUp: values.isPickUp,
      isDelivery: values.isDelivery,
      isBoth: values.isBoth,
      isVeg: values.isVeg,
      isNonVeg: values.isNonVeg,
      isAcceptingCash: values.isAcceptingCash,
      mfaEnabled: values?.mfaEnabled,
      cashAndCarry: values?.cashAndCarry,
      canInviteUser: values?.canInviteUser,
      packaging_charge: values.packaging_charge,
      deliveryCharge: values.deliveryCharge,
      outletAdminId: {
        outletAdminId: values.outletAdminId.outletAdminId,
        name: values.outletAdminId.name,
        mobile: values.outletAdminId.mobile,
        email: values.outletAdminId.email,
        address: values.outletAdminId.address,
        pancard: values.outletAdminId.pancard,
      },
      // Restaurant_category: categories.map((c) => ({ categoryId: c })),
      Restaurant_category: categories,
      bankDetailsId: {
        bankDetailsId: outletData?.bankDetailsId?.bankDetailsId,
        accountNumber: values.accountNumber,
        BankName: values.BankName,
        IFSCCode: values.IFSCCode,
        bankId: values?.bankId,
      },
      Timing: dates,
    };

    if (restaurantData?.publishProcessingStep === 0) {
      postbody.publishProcessingStep = 1;
    }
    //
    updateOutletData(outletIdNew, postbody)
      .then((res) => {
        if (restaurantData?.publishProcessingStep === 0) {
          setShowDialog(true);
        }
        setLoading(false);
        //
        dispatch(setLoadingSate(false));
        if (!file) {
          dispatch(
            authLogin({
              ...user,
              ...postbody,
              campusId: {
                campusName: selectedCampusName,
                campusId: values?.campusId,
                cityId: { city: selectedCityName },
              },
              headerImage: res.data.data[0].headerImage,
              logo: res.data.data[0].logo,
              Restaurant_category: postbody.Restaurant_category.map((c) => ({
                categoryId: c,
              })),
            })
          );
        }
        toast.success("Updated Successfully");
      })
      .catch((e) => {});
    if (file)
      axios
        .post(
          process.env.REACT_APP_BACKEND_BASE_URL +
            "outlet/upsertFssaiLicensePhoto",
          convertObjectToFormData({
            file: file,
            outletId: outletIdNew,
          }),
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          //
          setImg(res.data.data.FSSAI_License);
          dispatch(
            authLogin({
              ...user,
              ...postbody,
              campusId: {
                campusName: selectedCampusName,
                campusId: values?.campusId,
                cityId: { city: selectedCityName },
              },
              FSSAI_License: res.data.data[0].FSSAI_License,
              headerImage: res.data.data[0].headerImage,
              logo: res.data.data[0].logo,
              Restaurant_category: postbody.Restaurant_category.map((c) => ({
                categoryId: c,
              })),
            })
          );
        })
        .catch((error) => {});
  };

  return loadingOutletData || isFetching ? (
    <CustomLoader />
  ) : (
    <Formik
      initialValues={{ ...outletData, ...outletData?.bankDetailsId }}
      validationSchema={Yup.object().shape({
        IFSCCode: Yup.string()
          .required("IFSC Code is required")
          .matches(/^[A-Za-z]{4}\d{7}$/, "Invalid IFSC code"),
        accountNumber: Yup.string()
          .required("Bank Account Number is required")
          .matches(/^[0-9]{9,18}$/, "Invalid bank account number"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        packaging_charge: Yup.number()
          .typeError("Packaging charge must be a number")
          .min(0, "Packaging charge cannot be less than 0")
          .required("Packaging charge is required"),
        deliveryCharge: Yup.number()
          .typeError("Delivery charge must be a number")
          .min(0, "Delivery charge cannot be less than 0")
          .required("Delivery charge is required"),
        mobile: Yup.string()
          .required("Mobile number is required")
          .matches(mobilePattern, "Invalid mobile number"),
        outletName: Yup.string().required("Outlet Name is required"),
        address: Yup.string().required("Address is required"),
        bankId: Yup.string().required("Bank Name is required"),
        campusId: Yup.object().shape({
          campusId: Yup.string().required("Campus is required"),
        }),
        cityId: Yup.string().required("City is required"),
        outletAdminId: Yup.object().shape({
          name: Yup.string().required("Name is required"),
          pancard: Yup.string()
            .required("PAN Card is required")
            .matches(panCardPattern, "Invalid PAN Card format"),
        }),
      })}
      onSubmit={(values, { errors }) => {
        if (selectedDay?.length === 0) {
          setWarning(true);
          toast.warning("Please add at least 1 day");
          return;
        }
        if (!categories?.length) {
          toast.warning("Please select at least one category!");
          return;
        }
        if (restaurantData?.outletId) {
          handleOutletUpdate({ ...values, campusId: values.campusId.campusId });
        }
      }}
    >
      {(formik) => (
        <Form>
          <Box
            sx={{
              height: "calc(100vh - 170px)",
              padding:"0 20px 0 0 "
            }}
          >
            <FormikErrorScroller errors={formik.errors} dep={dep} />
            <PageHeader
              secondary
              title="Restaurant Details"
              subTitle={"View and update your Restaurant details"}
              rightUi={
                !isSmallScreen && <CustomButton
                  type="submit"
                  onClick={() => handleSave(formik.errors)}
                  loading={loading}
                >
                  Save Changes
                </CustomButton>
              }
            />

            {isSmallScreen && (
              <CustomButton
                  type="submit"
                  onClick={() => handleSave(formik.errors)}
                  loading={loading}
                  style={{marginBottom:"1rem",display:"flex",justifyContent:"flex-end"}}
                >
                  Save Changes
                </CustomButton>
            )}

            <Stack spacing={4}>
              <Box>
                <FormHeader>Restaurant Basic Details:</FormHeader>
                <Grid container spacing={"20px"}>
                  <Grid item xs={isSmallScreen ? 12 : 5}>
                    <CustomTextFieldFormik
                      name={"outletName"}
                      label="Restaurent Name"
                      placeholder="Enter Restaurent Name"
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 4}>
                    <CustomTextFieldFormik
                      name="email"
                      label="Email"
                      placeholder="Enter Email"
                      required={true}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 3}>
                    <CustomTextFieldFormik
                      name="mobile"
                      label="Mobile Number"
                      placeholder="Enter Mobile Number"
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 6}>
                    <CustomTextFieldFormik
                      name="address"
                      label="Restaurant Address"
                      placeholder="Enter Restaurant Address"
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 3}>
                    {/* <CustomTextFieldFormik
                    name="address"
                    label="Restaurant Address"
                    placeholder="Enter Restaurant Address"
                    required={true}
                  /> */}
                    <CustomSelectFormik
                      name="cityId"
                      label="Select City"
                      placeholder="Select City"
                      required={true}
                      menuList={cityList?.map((c) => ({
                        id: c.cityId,
                        value: c.cityId,
                        name: `${c.city}, ${c.state}, ${c.country}`,
                      }))}
                      onChange={(e) => {
                        const selectedCityId = e.target.value;
                        setSelectedCity(selectedCityId);
                        const selectedCity = cityList.find(
                          (c) => c.cityId === selectedCityId
                        );

                        if (selectedCity) {
                          console.clear();

                          setSelectedCityName(selectedCity.city);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 3}>
                    <CustomSelectFormik
                      name="campusId.campusId"
                      label="Select Campus"
                      placeholder="Select Campus"
                      required={true}
                      menuList={campusList?.data?.data?.map((c) => ({
                        id: c.campusId,
                        value: c.campusId,
                        name: c.campusName,
                      }))}
                      onChange={(e) => {
                        const selectedCampusId = e.target.value;
                        // setSelectedCity(selectedCityId);
                        const selectedCampus = campusList?.data?.data?.find(
                          (c) => c.campusId === selectedCampusId
                        );

                        if (selectedCampus) {
                          setSelectedCampusName(selectedCampus.campusName);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              {!restaurantData?.hasMess && (
                <Box>
                  <FormHeader>Restaurant Charges:</FormHeader>
                  <Grid container spacing={"12px"}>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                      <CustomTextFieldFormik
                        name="packaging_charge"
                        label="Packging Charges"
                        placeholder="Enter packaging charges"
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                      <CustomTextFieldFormik
                        name="convenienceFee"
                        label="Convenience Fee"
                        placeholder="Convenience Fee"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                      <CustomTextFieldFormik
                        name="commissionFee"
                        label="Commission Fee"
                        placeholder="Commission Fee"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                      <CustomTextFieldFormik
                        name="deliveryCharge"
                        label="Delivery Charge"
                        placeholder="Enter Delivery Charge"
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextFieldFormik
                        name="bankLabel"
                        label="Bank Label"
                        placeholder="Bank Label"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Box>
                <FormHeader>Restaurant Documents:</FormHeader>
                <Grid container spacing={"20px"}>
                  <Grid item xs={isSmallScreen ? 12 : 6}>
                    <Typography
                      color="black"
                      fontWeight={500}
                      sx={{
                        mb: 1,
                      }}
                    >
                      Restaurant Logo
                    </Typography>
                    <UpliadImageFieldWithoutFormik
                      sx={{
                        borderRadius: "50%",
                        objectFit: "cover",
                        height: "150px",
                        width: "150px",
                      }}
                      defaultImg={imageRounded}
                      defaultValue={outletData?.logo}
                      name="resto_img"
                      onChange={(file) => {
                        axios.post(
                          process.env.REACT_APP_BACKEND_BASE_URL +
                            "outlet/upsertLogoImage",
                          convertObjectToFormData({
                            file: file,
                            outletId: outletData?.outletId,
                          }),
                          {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 6}>
                    <NewFileUploadComponent
                      setFile={setFile1}
                      label={"Upload Restaurant Header"}
                      defaultImg={file1}
                      url={"outlet/upsertHeaderImage"}
                      title={"Upload Restaurant Header"}
                      text={"Upload your Restaurant picture"}
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 6}>
                    <NewFileUploadComponent
                      setFile={setImg}
                      label={"Upload FSSAI License"}
                      defaultImg={img}
                      imageUrl={outletData?.FSSAI_License}
                      title={"Upload License"}
                      text={"Upload License here"}
                      url={"outlet/upsertFssaiLicensePhoto"}
                      isAcceptFile={(file) => {
                        return file?.type === "application/pdf";
                      }}
                      warningMessage="Please select Image or PDF for FSSAI License"
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 6}>
                    <CustomTextFieldFormik
                      name="GSTIN"
                      label="Regular GSTIN (optional)"
                      placeholder="Enter GST number"
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 4}>
                    <CustomSelectFormik
                      name="bankId"
                      label="Select Bank Name"
                      placeholder="Select"
                      required={true}
                      // menuList={[
                      //   { id: 1, value: "HDFC", name: "HDFC" },
                      //   { id: 2, value: "ICICI", name: "ICICI" },
                      // ]}
                      menuList={bankList?.map((bank, index) => ({
                        id: bank.bankId,
                        value: bank.bankId,
                        name: bank.name,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 4}>
                    <CustomTextFieldFormik
                      name="IFSCCode"
                      label="IFSC Code"
                      placeholder="Enter IFSC Code"
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 4}>
                    <CustomTextFieldFormik
                      name="accountNumber"
                      label="Bank Account Number"
                      placeholder="Enter Account Number"
                      required={true}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <FormHeader>Owner Details:</FormHeader>
                <Grid container spacing={"20px"}>
                  <Grid item xs={12}>
                    <CustomTextFieldFormik
                      name={
                        outletData?.outletAdminId
                          ? "outletAdminId.name"
                          : "restaurantAdminId.name"
                      }
                      label="Owner Name"
                      placeholder="Enter Owner Name"
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 6}>
                    <CustomTextFieldFormik
                      // name={
                      //   restaurantData?.outletAdminId
                      //     ? "outletAdminId.mobile"
                      //     : "restaurantAdminId.mobile"
                      // }
                      name={"mobile"}
                      label="Owner Mobile Number"
                      placeholder="Enter Mobile Number"
                      // required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 6}>
                    <CustomTextFieldFormik
                      // name={
                      //   restaurantData?.outletAdminId
                      //     ? "outletAdminId.email"
                      //     : "restaurantAdminId.email"
                      // }
                      name={"email"}
                      label="Owner Email"
                      placeholder="Enter Email"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 8}>
                    <CustomTextFieldFormik
                      name={
                        outletData?.outletAdminId
                          ? "outletAdminId.address"
                          : "restaurantAdminId.address"
                      }
                      label="Your Address"
                      placeholder="Enter Your Address"
                    />
                  </Grid>
                  <Grid item xs={isSmallScreen ? 12 : 4}>
                    <CustomTextFieldFormik
                      name={
                        outletData?.outletAdminId
                          ? "outletAdminId.pancard"
                          : "restaurantAdminId.pancard"
                      }
                      label="Pancard Number "
                      placeholder="Enter Pancard Number "
                      required
                    />
                  </Grid>
                </Grid>
              </Box>

              {!restaurantData?.hasMess && (
                <Box>
                  <Grid container spacing={"24px"}>
                    <Grid item xs={12} md={6}>
                      <PaperBox shadowed>
                        <FormHeader>Establishment Details:</FormHeader>
                        <Stack>
                          <CustomCheckboxWithFormik
                            name="isDineIn"
                            label="Dine in"
                            checked={outletData?.isDineIn}
                          />
                          <CustomCheckboxWithFormik
                            name="isPickUp"
                            label="Pickup"
                            checked={outletData?.isPickUp}
                          />
                          <CustomCheckboxWithFormik
                            name="isDelivery"
                            label="Delivery"
                            checked={outletData?.isDelivery}
                          />
                        </Stack>
                      </PaperBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PaperBox shadowed>
                        <FormHeader>Type of Restaurant:</FormHeader>
                        <Stack>
                          <CustomCheckboxWithFormik
                            name="isVeg"
                            label="Veg"
                            checked={outletData?.isVeg}
                          />
                          <CustomCheckboxWithFormik
                            name="isNonVeg"
                            label="Non Veg"
                            checked={outletData?.isNonVeg}
                          />
                          <CustomCheckbox
                            // onChange={onChange}
                            name="isNonVeg"
                            // value={isNonVeg}
                            label="Non veg"
                            labelSx={{ visibility: "hidden" }}
                            checkboxSx={{ visibility: "hidden" }}
                          />
                        </Stack>
                      </PaperBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PaperBox shadowed>
                        <FormHeader>Type of Payment Mehod:</FormHeader>
                        <Stack>
                          <CustomCheckboxWithFormik
                            name="isAcceptingCash"
                            label="Cash On Delivery"
                            checked={outletData?.isAcceptingCash}
                          />
                          <CustomCheckboxWithFormik
                            name="isAcceptingOnline"
                            label="Pre Paid"
                            checked={true}
                          />
                        </Stack>
                      </PaperBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PaperBox shadowed>
                        <FormHeader>MultiFactor Authentication:</FormHeader>
                        <Stack>
                          <CustomCheckboxWithFormik
                            name="mfaEnabled"
                            label="MFA"
                            checked={outletData?.mfaEnabled}
                          />
                        </Stack>
                      </PaperBox>
                    </Grid>
                  </Grid>
                </Box>
              )}


              {restaurantData?.hasMess && (
                <Grid item xs={12} md={6}>
                  <PaperBox shadowed>
                    <FormHeader>Mess Details:</FormHeader>
                    <Stack>
                      <CustomCheckboxWithFormik
                        name="mfaEnabled"
                        label="MFA"
                        checked={outletData?.mfaEnabled}
                      />
                    </Stack>
                    <Stack>
                              <CustomCheckboxWithFormik
                                name="cashAndCarry"
                                label="Cash and Carry"
                                checked={outletData?.cashAndCarry}
                              />
                            </Stack>
                            <Stack>
                              <CustomCheckboxWithFormik
                                name="canInviteUser"
                                label="Invite Customers"
                                checked={outletData?.canInviteUser}
                              />
                            </Stack>
                  </PaperBox>
                </Grid>
              )}
              {!restaurantData?.hasMess && (
                <Box>
                  <FormHeader>Type of Select:</FormHeader>
                  <StackRow gap={"12px"} mb={1} flexWrap="wrap">
                    {categoryList?.data?.data?.map((category) => {
                      const isCurrent = categories.includes(
                        category.categoryId
                      );

                      return (
                        <CustomChip
                          onClick={() => {
                            setCategories((prevCategories) =>
                              prevCategories.includes(category.categoryId)
                                ? prevCategories.filter(
                                    (c) => c !== category.categoryId
                                  )
                                : [...prevCategories, category.categoryId]
                            );
                          }}
                          key={category?.categoryId}
                          label={category.category}
                          sx={{
                            border: "1px #94A3B8 solid",
                            cursor: "pointer",
                            background: isCurrent && "#2e7d32",
                            color: isCurrent ? "white" : "black",
                            "&:hover": {
                              background: isCurrent ? "#EF4444" : "#2e7d32",
                              color: "white",
                              "& svg": {
                                fill: isCurrent ? "white" : "white",
                              },
                            },
                            "& svg": {
                              transition: "0.3s",
                              fill: isCurrent && "white",
                              "&:active": {
                                transform: "scale(0.8)",
                              },
                              "&:hover": {},
                            },
                          }}
                          icon={isCurrent ? <Remove /> : <Add />}
                        />
                      );
                    })}
                  </StackRow>
                  {categories.length === 0 && (
                    <Typography variant="body2" color="error">
                      Please select at least one category
                    </Typography>
                  )}
                </Box>
              )}
              <CustomDialog
                sx={{
                  borderRadius: "40px",
                }}
                open={showDialog}
                width="600px"
                buttonComp={() => <></>}
              >
                <Box
                  sx={{
                    height: "350px",
                    display: "grid",
                    placeContent: "center",
                  }}
                >
                  <CParticles />

                  <Headline1
                    sx={{
                      fontSize: "48px",
                      textAlign: "center",
                    }}
                  >
                    Woohoo!
                  </Headline1>
                  <Headline2
                    sx={{
                      fontSize: "32px",
                      textAlign: "center",
                    }}
                  >
                    your profile is complete!
                  </Headline2>
                </Box>
              </CustomDialog>
              {/* <Box>
              <FormHeader>Times:</FormHeader>
              <Grid container spacing={"20px"}></Grid>
            </Box> */}
              <Box>
                {/* <FormHeader>Day:</FormHeader>
              <CustomCheckbox
                name="all_days"
                // value={isDineIn}
                label="All Days"
                labelSx={{
                  fontSize: "14px",
                }}
                // onChange={onChange}
              /> */}
                {!restaurantData?.hasMess ? (
                  <MoreInformation
                    handler={setDates}
                    warning={warning}
                    setSelectedDay={setSelectedDay}
                    timeDataFromUpdateOutlet={outletData?.Timing}
                  />
                ) : (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box>
                      <Box>
                        <Headline1>Meal Time</Headline1>
                      </Box>
                      {Object.keys(mealTimes).map((meal) => (
                        <Box
                          style={{ display: "flex", flexDirection: "column" }}
                          key={meal}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 2,
                              mt: 4,
                            }}
                          >
                            {/* Checkbox added before meal label */}
                            <Checkbox
                              checked={mealTimes[meal].enabled}
                              onChange={(e) =>
                                handleCheckboxChange(meal, e.target.checked)
                              }
                            />
                            <Typography fontSize={"1.3rem"} fontWeight={450}>
                              {meal}
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                          </Box>

                          {/* TimePicker components, only shown if the checkbox is checked */}
                          {mealTimes[meal].enabled && (
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                              }}
                            >
                              <Box>
                                <TimePicker
                                  label="Start Time"
                                  value={mealTimes[meal].startTime}
                                  onChange={(newValue) =>
                                    handleTimeChange(
                                      meal,
                                      "startTime",
                                      newValue
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  fullWidth
                                />
                              </Box>
                              <Box>
                                <TimePicker
                                  label="End Time"
                                  value={mealTimes[meal].endTime}
                                  onChange={(newValue) =>
                                    handleTimeChange(meal, "endTime", newValue)
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  fullWidth
                                />
                              </Box>
                              <Box>
                                <TimePicker
                                  label="RSVP Deadline"
                                  value={mealTimes[meal].rsvpDeadline}
                                  onChange={(newValue) =>
                                    handleTimeChange(
                                      meal,
                                      "rsvpDeadline",
                                      newValue
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  fullWidth
                                />
                              </Box>
                            </Box>
                          )}
                        </Box>
                      ))}

                      {/* Save button */}
                      <Box
                        style={{
                          display: isSmallScreen ? "" : "flex",
                          justifyContent: "flex-end",
                          marginTop: "2rem",
                          paddingRight: "36rem",
                        }}
                      >
                        <CustomButton
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                        >
                          {loading ? "Saving..." : "Save"}
                        </CustomButton>
                      </Box>
                    </Box>
                  </LocalizationProvider>
                )}
                {/* <OnboardDaysFields
                noHeader
                selectedState={days}
                setSelectedState={setDays}
              /> */}
                <br />
              </Box>
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default General;

const fetchMealTimesFromApi = async (outletIdNew, getMealTime) => {
  try {
    const getTime = await axios.get(`${getMealTime}${outletIdNew}`);
    console.log("get time res ", getTime?.data?.data);
    const timeStamp = getTime?.data?.data;

    return {
      Breakfast: {
        startTime: dayjs(timeStamp[0]?.startTime),
        endTime: dayjs(timeStamp[0]?.endTime),
        rsvpDeadline: dayjs(timeStamp[0]?.rsvpDeadline),
        enabled: timeStamp[0]?.enabled,
      },
      Lunch: {
        startTime: dayjs(timeStamp[1]?.startTime),
        endTime: dayjs(timeStamp[1]?.endTime),
        rsvpDeadline: dayjs(timeStamp[1]?.rsvpDeadline),
        enabled: timeStamp[1]?.enabled,
      },

      Hightea: {
        startTime: dayjs(timeStamp[2]?.startTime),
        endTime: dayjs(timeStamp[2]?.endTime),
        rsvpDeadline: dayjs(timeStamp[2]?.rsvpDeadline),
        enabled: timeStamp[2]?.enabled,
      },
      Dinner: {
        startTime: dayjs(timeStamp[3]?.startTime),
        endTime: dayjs(timeStamp[3]?.endTime),
        rsvpDeadline: dayjs(timeStamp[3]?.rsvpDeadline),
        enabled: timeStamp[3]?.enabled,
      },
    };
  } catch (error) {
    console.log("Error while fetch meal time");
  }
};
