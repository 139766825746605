import Login from "./pages/Login";
import SendForgotEmail from "./pages/SendForgotEmail";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LayoutWrapper from "./components/layoutUI/LayoutWrapper";
import Dashboard from "./pages/Dashboard";
import Settlements from "./pages/Settlements";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Outlets from "./pages/Outlets";
import OutletOnboard from "./pages/Outlets/OutletOnboard";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AnimatePresence } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoutes from "./components/layoutUI/PrivateRoute";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./Redux Store/store";
import Items from "./pages/Menu/Items";
import ItemAdd from "./pages/Menu/Items/ItemAdd";
import Categories from "./pages/Menu/Categories";
import CategoriesAdd from "./pages/Menu/Categories/CategoriesAdd";
import SubCategories from "./pages/Menu/SubCategories";
import SubCategoriesAdd from "./pages/Menu/SubCategories/SubCategoriesAdd";
import Taxation from "./pages/Menu/Taxation";
import Customers from "./pages/Customers";
import Orders from "./pages/Orders";
import Settings from "./pages/Settings";
import OrderSingle from "./pages/OrderSingle";
import notification from "../src/assets/images/notification.mp3";
import Loading from "./pages/Loading/Loading";
import Publish from "./pages/Publish";
import { useCallback, useEffect, useRef, useState } from "react";
import InternetStatusChecker from "./components/pageUI/InternetStatusChecker";
import {
  getPendingOrderList,
  getPendingOrderList1,
} from "./Services/pendingOrder";
import {
  clearPendingOrder,
  setRealtimeOrder,
  setpendingTOCancelOrders,
} from "./Redux Store/Slices/realtimeOrder";
import outletId from "./utils/outletId";
import useOutletId from "./utils/outletId";
import { Howl } from "howler";
import Kot from "./pages/Kot/Kot";
import { setKotData } from "./Redux Store/Slices/realtimeKot";
import { authLogin } from "./Redux Store/Slices/auth";
import OutletSubscription from "./pages/OutletSubscription/OutletSubscription";
import axios from "axios";
import AddonVariations from "./pages/Menu/AddonVariations/AddonVariations";
import EditAddonVariation from "./pages/Menu/AddonVariations/EditAddonVariation";
import Addons from "./pages/Menu/AddonVariations/Addons";
import EditAddon from "./pages/Menu/AddonVariations/EditAddon";
import EditAddonItem from "./pages/Menu/AddonVariations/EditAddonItem";
import MessDashboard from "./MessManager/MessDashboard";
import MessMenu from "./MessManager/MessMenu";
import EditMessMenu from "./MessManager/EditMessMenu";
import ConfirmPassowrd from "./pages/ConfirmPassowrd";
import MessStudents from "./MessManager/MessStudents";
import RsvpAnalyse from "./MessManager/RsvpAnalyse";
import MessSubscription from "./pages/MessSubscription/MessSubscription";
import { outletUrl } from "./utils/urls";
import CashAndCarry from "./MessManager/CashAndCarry";
import MDashboard from "./MessManager/MDashboard";

const theme = createTheme({
  typography: {
    // fontSize : 16,
    fontFamily: "Inter",
  },
  palette: {
    primary: {
      main: "#4F46E5",
      contrastText: "#fff",
    },
    secondary: {
      main: "#4F46E5",
      contrastText: "#fff",
    },

    text: {
      primary: "#0F172A",
    },
  },
});
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// const routers = [
//   {
//     path: "/",
//     // element: <Publish />,
//     element: user?.hasMess ? <Dashboard /> : <MessDashboard/>,
//   },
//   {
//     path: "/settlements",
//     element: <Settlements />,
//   },
//   {
//     path: "/outlets",
//     element: <Outlets />,
//   },
//   {
//     path: "/create-outlet",
//     element: <OutletOnboard />,
//   },
//   {
//     path: "/outlets/editOutlet/:outletId",
//     element: <OutletOnboard />,
//   },
//   {
//     path: "/outlets/:id",
//     element: <Outlets />,
//   },
//   {
//     path: "/outlets/onboard",
//     element: <OutletOnboard />,
//   },
//   {
//     path: "/menu/items",
//     element: <Items />,
//   },
//   {
//     path: "/menu/items/add",
//     element: <ItemAdd />,
//   },
//   {
//     path: "/menu/items/:cId",
//     element: <ItemAdd />,
//   },
//   {
//     path: "/menu/categories",
//     element: <Categories />,
//   },
//   {
//     path: "/menu/categories/add",
//     element: <CategoriesAdd />,
//   },
//   {
//     path: "/menu/categories/:cId",
//     element: <CategoriesAdd />,
//   },
//   {
//     path: "/menu/subcategories",
//     element: <SubCategories />,
//   },
//   {
//     path: "/menu/subcategories/add",
//     element: <SubCategoriesAdd />,
//   },
//   {
//     path: "/menu/subcategories/:cId",
//     element: <SubCategoriesAdd />,
//   },
//   {
//     path: "/menu/variation",
//     element: <AddonVariations />},
//   {
//     path: "/menu/addon",
//     element: <Addons />,
//   },
//   {
//     path: "/menu/addon/:addonid",
//     element: <EditAddon />,
//   },
//   {
//     path: "/menu/addonItem/:addonidd",
//     element: <EditAddonItem />,
//   },
//   {
//     path: "/menu/variation/:aid",
//     element: <EditAddonVariation />,
//   },
//   {
//     path: "/menu/taxation",
//     element: <Taxation />,
//   },
//   {
//     path: "/customers",
//     element: <Customers />,
//   },
//   {
//     path: "/kot",
//     element: <Kot />,
//   },
//   {
//     path: "/orders",
//     element: <Orders />,
//   },
//   {
//     path: "/orders/:oId",
//     element: <OrderSingle />,
//   },
//   {
//     path: "/settings",
//     element: <Settings />,
//   },
//   {
//     path: "/messmenu",
//     element: <MessMenu />,
//   },
// ];
function App() {
  const [audio] = useState(new Howl({ src: [notification] }));
  const dispatch = useDispatch();
  const outletIdnew = useSelector((state) => state.outledId.outletId);
  const { user } = useSelector((state) => state.auth);

  const routers = [
    {
      path: "/",
      // element: <Publish />,
      element: !user?.hasMess ? <Dashboard /> : <MDashboard />,
    },
    {
      path: "/settlements",
      element: <Settlements />,
    },
    {
      path: "/outlets",
      element: <Outlets />,
    },
    {
      path: "/create-outlet",
      element: <OutletOnboard />,
    },
    {
      path: "/outlets/editOutlet/:outletId",
      element: <OutletOnboard />,
    },
    {
      path: "/outlets/:id",
      element: <Outlets />,
    },
    {
      path: "/outlets/onboard",
      element: <OutletOnboard />,
    },
    {
      path: "/menu/items",
      element: <Items />,
    },
    {
      path: "/menu/items/add",
      element: <ItemAdd />,
    },
    {
      path: "/menu/items/:cId",
      element: <ItemAdd />,
    },
    {
      path: "/menu/categories",
      element: <Categories />,
    },
    {
      path: "/menu/categories/add",
      element: <CategoriesAdd />,
    },
    {
      path: "/menu/categories/:cId",
      element: <CategoriesAdd />,
    },
    {
      path: "/menu/subcategories",
      element: <SubCategories />,
    },
    {
      path: "/menu/subcategories/add",
      element: <SubCategoriesAdd />,
    },
    {
      path: "/menu/subcategories/:cId",
      element: <SubCategoriesAdd />,
    },
    {
      path: "/menu/variation",
      element: <AddonVariations />,
    },
    {
      path: "/menu/addon",
      element: <Addons />,
    },
    {
      path: "/menu/addon/:addonid",
      element: <EditAddon />,
    },
    {
      path: "/menu/addonItem/:addonidd",
      element: <EditAddonItem />,
    },
    {
      path: "/menu/variation/:aid",
      element: <EditAddonVariation />,
    },
    {
      path: "/menu/taxation",
      element: <Taxation />,
    },
    {
      path: "/customers",
      element: <Customers />,
    },
    {
      path: "/kot",
      element: <Kot />,
    },
    {
      path: "/orders",
      element: <Orders />,
    },
    {
      path: "/orders/:oId",
      element: <OrderSingle />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
    {
      path: "/messmenu",
      element: <MessMenu />,
    },
    {
      path: "/mess/menu/:menuid1/:menuid2/:menuid3/:menuid4",
      element: <EditMessMenu />,
    },
    {
      path: "/messstudents",
      element: <MessStudents />,
    },
    {
      path: "/rsvpanalysis",
      element: <RsvpAnalyse />,
    },
    {
      path: "/cashandcarry",
      element: <CashAndCarry />,
    },
    {
      path: "/mdashboard",
      element: <MDashboard />,
    },
  ];

  const [isSubscriptionValid, setIsSubscriptionValid] = useState(true);
  const hasMess = useSelector((state) => state?.auth?.user?.hasMess);

  const pendingEventSource = useRef(null);
  const curentEventSource = useRef(null);
  const updateEventSource = useRef(null);

  useEffect(() => {
    const isPublishCompleted = localStorage.getItem("isPublishCompleted");
    if (!isPublishCompleted) localStorage.setItem("isPublishCompleted", 0);

    document.addEventListener("custom:logout", (result) => {
      if (result?.detail === "logout") {
        eventConectionClose();
      }
    });
  }, []);

  // const [outletIdds, setOutletIdds] = useState([]);
  // const [outletIddds, setOutletIddds] = useState([]);
  const [primeOutlet, setPrimeOutlet] = useState();
  // console.log("outletIddds ",outletIddds)


  const getOutletLists = useCallback(async () => {
    if (!user?.outletId || !outletIdnew) return ;

    try {
      const { data } = await axios.get(`${outletUrl}${user.outletId}`);
      const ids = data?.data?.map(o => o.outletId) || [];
      const updatedOutletIddds = [...new Set([...ids, user.outletId])];

      // setOutletIddds(updatedOutletIddds);

      dispatch(clearPendingOrder());

      if (updatedOutletIddds?.length > 1 && outletIdnew === user?.outletId) {
        console.log("Fetching for multiple outlets...");
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/getPendingOrdersListForOutlets`,
          { outletIds: updatedOutletIddds }
        );
        dispatch(setRealtimeOrder(res?.data?.data));
      } else {
        console.log("Fetching for single outlet...");
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/getPendingOrder/${outletIdnew}`
        );
        dispatch(setRealtimeOrder(res?.data?.data));
      }
    } catch (error) {
      console.error("Error fetching outlet data:", error);
    }
  }, [user?.outletId, outletIdnew, outletUrl, dispatch]);

  useEffect(() => {
    getOutletLists();
  }, [getOutletLists,user?.outletId, outletIdnew]);



 
  // useEffect(() => {
  //   if (outletIdnew) {
  //     dispatch(clearPendingOrder([]));
  //     getPendingOrderList(outletIdnew).then((res) => {
  //       dispatch(setRealtimeOrder(res?.data?.data));
  //     });
  //   }
  // }, [user?.outletId, outletIdnew]);

  useEffect(() => {
    eventConectionStart();
    return () => {
      eventConectionClose();
    };
  }, []);

  // Check Subscription is valid or not
  useEffect(() => {
    user?.outletId && checkSubscriptionValid();
  }, [user?.outletId]);

  const checkSubscriptionValid = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_BACKEND_BASE_URL +
          "payment/customer/check-subscription-valid",
        {
          outletId: user?.outletId,
        }
      );

      // console.log("sub valid res ",res?.data);
      setIsSubscriptionValid(res?.data?.isValid);
    } catch (error) {
      console.log("Error while call sub valid api");
    }
  };

  function eventConectionStart() {
    if (user?.outletId && !updateEventSource.current) {
      updateEventSource.current = new EventSource(
        `${process.env.REACT_APP_BACKEND_BASE_URL}outlet/realtimeOutletWeb/${user?.outletId}`
      );
      updateEventSource.current?.addEventListener("updateOutlet", (e) => {
        if (JSON.parse(e?.data)) {
          dispatch(authLogin(JSON.parse(e?.data)));
        }
      });
    }
    if (user?.outletId && !curentEventSource?.current) {
      curentEventSource.current = new EventSource(
        `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/realtimeCurrentOrder/${user?.outletId}`
      );

      curentEventSource.current?.addEventListener("order", (e) => {
        if (JSON.parse(e?.data)) {
          dispatch(setKotData(JSON.parse(e?.data)?.data || []));
        }
      });
    }
    if (user?.outletId && !pendingEventSource?.current) {
      pendingEventSource.current = new EventSource(
        `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/realtimePendingOrder/${user?.outletId}`
      );

      pendingEventSource.current?.addEventListener("neworder", (e) => {
        if (JSON.parse(e?.data)) {
          audio.play();
          toast.info("New order recieved", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          dispatch(setRealtimeOrder(JSON.parse(e?.data)));
        }
      });
      pendingEventSource.current?.addEventListener("cancelorder", (e) => {
        if (JSON.parse(e?.data)) {
          dispatch(setpendingTOCancelOrders(JSON.parse(e?.data)));
        }
      });
    }
  }
  function eventConectionClose() {
    if (pendingEventSource.current) {
      pendingEventSource?.current?.close();
      pendingEventSource.current = null;
    }
    if (curentEventSource.current) {
      curentEventSource?.current?.close();
      curentEventSource.current = null;
    }
    if (updateEventSource.current) {
      updateEventSource?.current?.close();
      updateEventSource.current = null;
    }
  }

  useEffect(() => {
    window.addEventListener("beforeunload", function () {
      // Close EventSource connection
      eventConectionClose();
    });
  }, []);

  return (
    // <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <InternetStatusChecker />
      {!isSubscriptionValid &&
        (hasMess ? <MessSubscription /> : <OutletSubscription />)}
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AnimatePresence mode="wait">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/login/forgot-password"
                  element={<SendForgotEmail />}
                />
                <Route
                  path="/login/confirm-password"
                  element={<ConfirmPassowrd />}
                />
                <Route path="/create-outlet" element={<OutletOnboard />} />
                <Route path="/" element={<ProtectedRoutes />}>
                  {routers.map(({ element, path }) => (
                    <Route
                      key={path}
                      path={path}
                      element={<LayoutWrapper>{element}</LayoutWrapper>}
                    />
                  ))}
                </Route>
                {/* 👈 Renders at /app/ */}
              </Routes>
            </AnimatePresence>
          </BrowserRouter>
          {/* <RouterProvider router={router}> */}
          {/* <Login /> */}

          {/* <LayoutWrapper> */}
          {/* <Dashboard /> */}

          {/* <Settlements /> */}
          {/* </LayoutWrapper> */}
          {/* </RouterProvider> */}
          <Loading />
        </ThemeProvider>
      </QueryClientProvider>
    </LocalizationProvider>
    // </Provider>
  );
}

export default App;
