import {
  AppBar,
  Avatar,
  Box,
  Chip,
  Container,
  IconButton,
  Menu,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import React, { useEffect, useState } from "react";
import BrandPng from "../../../assets/images/newLogo.png";
import AvatarPng from "../../../assets/images/Avatar.png";
import HeaderNavbar from "./HeaderNavbar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountSvg from "../../svgComponents/AccountSvg";
import SettingsSvg from "../../svgComponents/SettingsSvg";
import AboutSvg from "../../svgComponents/AboutSvg";
import HelpSvg from "../../svgComponents/HelpSvg";
import LogoutSvg from "../../svgComponents/LogoutSvg";
import Lead from "../../typographyUI/Lead";
import CustomMenu from "../CustomMenu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Headline1 from "../../typographyUI/Headline1";
import Title from "../../typographyUI/Title";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../Redux Store/Slices/auth";
import { toast } from "react-toastify";
import RestoListFilter from "../../pageUI/filters/RestoListFilter";
import { setOutletId } from "../../../Redux Store/Slices/outletId";
import {useSmallScreen} from "../../../utils/mediaQueries";
const Header = ({sideBarHandler}) => {
  const isSmallScreen = useSmallScreen();
  const { user } = useSelector((state) => state.auth);
  const outletIdNew = useSelector((state) => state.outledId.outletId);


  const [title, setTitle] = useState("");
  const [noRestoFilter, setNoRestoFilter] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { outletId } = useParams();


  useEffect(() => {
    setTitle("");
    if (location.pathname == "/outlets") setTitle(user.restaurantName);
    if (location.pathname == "/outlets/onboard") setTitle("Let’s Onboard 👋");
    if (location.pathname == "/settings" || location.pathname == "/outlets")
      setNoRestoFilter(true);
    else setNoRestoFilter(false);
    if (location.pathname == `/outlets/editOutlet/${outletId}` || location.pathname == "/outlets"){
      setNoRestoFilter(true);
    }
    else {setNoRestoFilter(false);}
    // if (location.pathname == "/outlets") setNoRestoFilter(true); else setNoRestoFilter(false)
  }, [location.pathname]);
  useEffect(() => {

    dispatch(setOutletId(user?.outletId));
  }, []);
  const matches = useMediaQuery('(max-width: 1200px)');

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: "none",
        background: "white",
        color: "#0F172A",
        px: isSmallScreen ? "15px" :"32px",
        marginLeft: "auto",
        borderBottom: " 1px #E2E8F0 solid",
        position: "sticky",
        top: 0,
        zIndex: 30,
      }}
    >
      <Toolbar
        disableGutters
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
         <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={sideBarHandler}
          sx={{ mr: isSmallScreen ? 0 : 2, display:matches ? 'flex' : 'none'}}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
        <Box display={"flex"} flexDirection={"row"}
        sx={{
        }}
        >
          <img
            src={BrandPng}
            style={{
              width:isSmallScreen ? "125px" : "145px",
              height: isSmallScreen ? "25px" : "32px",
              objectFit: "contain",
              marginBottom: "16px",
              marginTop: "16px",
              marginLeft: "-8px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          />
        </Box>

        <Box
          sx={{
            py: 1,
            flex: "1",
            // paddingLeft:
            //   location.pathname == "/outlets/onboard" ? "0" : "144px",
            display: "flex",
            justifyContent: isSmallScreen ? "center" : "center",
            // border:"1px solid red",
            margin: "auto",
          }}
        >
          <Box
            sx={
              {
                // marginLeft : '10rem'
                // alignSelf:"center"
              }
            }
          >
            {title !== "Let’s Onboard 👋" && !noRestoFilter && (
              <RestoListFilter
              matches={matches}
                onClick={(label, id) => {
                  dispatch(setOutletId(id));
                }}
              />
            )}
          </Box>
          <Title
            color="#334155"
            fontSize={isSmallScreen ? "14px" : "24px"}
            textAlign={
              // location.pathname == "/outlets/onboard" ? "center" : "left"
              "center"
            }
          >
            {title}
          </Title>
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <CustomMenu
            ButtonComp={(props) => (
              <Chip
                deleteIcon={<ExpandMoreIcon />}
                onDelete={(e) => {
                  props.onClick(e);
                }}
                avatar={
                  <Avatar sx={{ height: "3.3rem", width: "3.3rem" }}>
                    {user?.outletAdminId?.name?.[0]}
                  </Avatar>
                }
                {...props}
                sx={{
                  "& .MuiAvatar-root": {
                    height: "2rem",
                    width: "2rem",
                  },
                  background: "#F8FAFC",
                  height: "2.5rem",
                }}
              />
            )}
            menuList={ [
              // { id: 1, label: "My Account", icon: <AccountSvg /> },
              { id: 2, label: "Settings", icon: <SettingsSvg /> },
              // { id: 3, label: "About", icon: <AboutSvg /> },
              // { id: 4, label: "Help & Support", icon: <HelpSvg /> },
              { id: 5, label: "Logout", icon: <LogoutSvg /> },
            ].filter((f) => {
              if(user?.outletStaffData?.outletStaffAuthUId){
                if(user?.outletStaffData?.roleId?.access?.includes(f.label) || f.label =="Logout"){
                  return true;
                }
                else{
                  return false;
                }
              }else{
                return true;
              }
            })}
            menuOnClick={(e) => {
              if (e.label === "Settings") {
                navigate("/settings");
              } else if (e.label === "Logout") {
                dispatch(logout());
                toast.success("Logged out successfully!");
                navigate("/login");
              }
            }}
          >
            <Stack direction={"row"} spacing={2} mb={"1.25rem"}>
              <Avatar sx={{}}>{user?.outletAdminId?.name?.[0] || ""}</Avatar>
              <Stack>
                <Typography
                  sx={{
                    color: "#334155",
                    fontWeight: "500",
                  }}
                >
                  {user?.outletStaffData?.outletStaffAuthUId ? user?.outletStaffData?.name : user?.outletAdminId?.name}
                </Typography>
                <Lead>{user?.outletStaffData?.outletStaffAuthUId ? (user?.outletStaffData?.roleId?.role || "Outlet Staff") : "Outlet Admin"}</Lead>
              </Stack>
            </Stack>
          </CustomMenu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
